<template>
  <div class="index">
    <img :src="$getImgUrl('assets/bg-1.png')" alt="" />
    <div class="box">
      <img :src="$getImgUrl('assets/360.png')" alt="" @click="$setRead(1, 1)" />
    </div>
    <img class="title-img" :src="$getImgUrl('assets/title1-1.png')" alt="" />
    <div class="box">
      <img :src="$getImgUrl('assets/video.png')" alt="" />
      <div class="video">
        <m-video-player
          src="https://zk.ohedu.cn/upload/video/filename/16738/SH/master.m3u8"
          name="1"
          @play="$setRead(1, 2)"
        >
        </m-video-player>
      </div>
    </div>
    <img class="title-img" :src="$getImgUrl('assets/title1-2.png')" alt="" />
    <div class="box">
      <img :src="$getImgUrl('assets/box-3.png')" alt="" />
      <div class="farm-list" @click="$setRead(1, 3)">
        <swiper>
          <swiper-slide v-for="n in farm" :key="n.id">
            <div class="farm">
              <p class="farm-name">{{ n.title }}</p>
              <p class="farm-label">
                <span>{{ n.tltleE }}</span>
              </p>
              <img :src="$getImgUrl(`assets/farm/${n.title}.png`)" alt="" />
              <p class="farm-more" @click="$router.push('/farm?id=' + n.id)">
                查看详情
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { farm } from "./list";
export default {
  name: "App",
  components: {},
  data() {
    return {
      farm,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
  
<style lang="less" scoped>
.farm-list {
  top: 20px;
  overflow: hidden;

  > div {
    width: 225px;
    margin: 0;
    overflow: visible;
  }

  .farm {
    width: 210px;
    height: 230px;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 0 0 15px;
    text-align: center;

    .farm-name {
      font-size: 16px;
      font-weight: bold;
      padding: 4px 0 0;
    }
    .farm-label {
      font-size: 14px;
      color: #707070;
      padding: 2px 0 2px;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }

    .farm-more {
      width: 80px;
      height: 26px;
      background-image: linear-gradient(180deg, #40b178 0%, #1e7943 100%);
      border-radius: 16px;
      line-height: 26px;
      color: #ffffff;
      margin: auto;
      margin-top: 10px;
      font-size: 13px;
    }
  }
}
</style>